import React from "react";
import { graphql } from "gatsby";
import { document } from "browser-monads";
import { useSize } from "ahooks";

// Components
import AboutAuthor from "../../components/aboutAuthor";
import Hero from "../../components/hero";
import HeroRedesigned from "../../components/heroRedesigned";
import Layout from "../../components/layout";
import PageBuilder from "../../components/pageBuilder";
import ShareComponent from "../../components/shareComponent";
import Form from "../../components/form";
import Seo from "../../components/seo/seo";

// Constants
import API from "../../constants/api";
import BREAKPOINTS from "../../constants/breakpoints";

// Helpers
import generateRandomId from "../../helpers/generateRandomId";
import { getVideoDataInPage } from "../../helpers/getVideoDataInPage";

// Hooks
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import useCategoriesList from "../../hooks/useCategoriesMenu";

// Styles
import * as styles from "../blogsStyles.module.scss";

function RsPost({ data, pageContext }) {
  const size = useSize(document.querySelector("body"));

  const { allSanityRsCategory, sanityRs } = data;
  const content = sanityRs?.tabs?._rawContent;
  const authorContent = content?.author;
  const heroContent = content?.hero;
  const pageBuilderContent = content?.pageBuilder;
  const formContent = content?.cta;
  const seo = sanityRs?.tabs?._rawSeo;
  const heroTitle = heroContent?.heroText?.titleText;
  const publishDate = content?.publishDate;
  const { _updatedAt } = sanityRs;
  const HeroComponent = heroContent?.isRedesigned ? HeroRedesigned : Hero;
  const video = getVideoDataInPage(pageBuilderContent);

  const categoriesList = useCategoriesList(
    API.resources,
    allSanityRsCategory?.nodes
  );

  const breadcrumbsList = [
    {
      _key: generateRandomId(),
      urlLabel: "Resources",
      url: API.resources.slice(1),
    },
    ...categoriesList.slice(1),
  ];

  const breadcrumbs = useBreadcrumbs(breadcrumbsList, false);

  return (
    <Layout>
      <Seo
        title={heroTitle}
        image={heroContent?.bgImage}
        author={authorContent?.name}
        breadcrumbs={breadcrumbs}
        isBlogPost
        path={pageContext?.pagePath}
        datePublished={publishDate}
        dateModified={_updatedAt}
        video={video}
        {...seo}
      />
      <HeroComponent
        imageClassName={styles.heroImg}
        breadcrumbs={breadcrumbs}
        image={heroContent?.bgImage}
        subTitle={heroContent?.heroText.subtitleText}
        title={heroContent?.heroText.titleText}
      />
      <div className={styles.mainContentWrapper}>
        <div className={styles.sideMenuWrapper}>
          {size?.width > BREAKPOINTS.lg && (
            <div className={styles.stickyContent}>
              {authorContent && (
                <div className={styles.aboutAuthorWrapper}>
                  <AboutAuthor author={authorContent} vertical />
                </div>
              )}
              <ShareComponent isBorder={false} />
            </div>
          )}
        </div>
        <div className={styles.onePostBlock}>
          {pageBuilderContent && (
            <PageBuilder pageBuilderContents={pageBuilderContent} />
          )}
        </div>
        <div className={styles.bottomContentWrapper}>
          {size?.width < BREAKPOINTS.lg && (
            <>
              {authorContent && (
                <div className={styles.aboutAuthorWrapper}>
                  <AboutAuthor author={authorContent} />
                </div>
              )}
              <ShareComponent isBorder={false} />
            </>
          )}
        </div>
      </div>
      <div className={styles.bottomContentWrapper}>
        {formContent && <Form {...formContent} />}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    sanityRs(tabs: { content: { slug: { current: { eq: $slug } } } }) {
      _updatedAt
      tabs {
        _rawContent(resolveReferences: { maxDepth: 10 })
        _rawSeo(resolveReferences: { maxDepth: 10 })
      }
    }
    allSanityRsCategory {
      nodes {
        id
        _rawSlug
        catColor
        title
      }
    }
  }
`;

export default RsPost;
